<template>
  <div class="container">
        <!-- Dropdown menu -->
        
    <div class="dropdown d-flex flex-row align-items-center justify-content-start">
      <span>Select: </span>
      <select name="player" @change="onUserChange($event)">
        <option value="" disabled selected>Player</option>
        <option v-for="item in users" :value=item :key="item">{{ item }}</option>
      </select>
      <span style="display: none;" ref="playerInfo">SteamID: {{ this.user ? this.user.steamId : undefined }}<br>Total playtime: {{ this.user ? this.user.playtime : undefined }}</span>
      <span style="display: none;color: red;" ref="playerError">Error</span>
      <div class="d-flex flex-row align-items-center justify-content-center" v-if="!loading">
        <button type="button" class='reload' @click.prevent="reloadGraph()">Reload</button>
      </div>
    </div>
    <div class="loading-spinner" v-if="loading">
      <img src="../../public/loading.png" alt="Loading" class="spin" />
    </div>
    <div class="chart">
      <canvas id="radar-chart" width="320" height="200"></canvas>
    </div>
    <div class="games-sel d-flex flex-row align-items-center justify-content-center">
      <div v-for="game in games" class="mx-5">
        <p><span v-html="game.name"></span>
          <input @input="game.selected = !game.selected" type="checkbox" :checked="game.selected" :id="game.id">
        </p>
      </div>
    </div>
    <div class="chart d-flex flex-column align-items-center">
      <canvas id="line-chart"></canvas>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-center m-5">
      <div class="">
        <datepicker v-model="dtFrom" @selected="handleDateChange()" format="dd-MM-yyyy" />
      </div>
      <div class="">
        <datepicker v-model="dtTo" @selected="handleDateChange()" format="dd-MM-yyyy" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import Datepicker from 'vuejs-datepicker';
import { VueLoading } from "vue-loading-template";
// Import Bootstrap
//import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

export default {
  name: 'App',
  components: {
    Datepicker,
    VueLoading
  },
  // created() {
  //   this.reloadGraph();
  // },
  data() {
    return {
      loading: false,
      wip: false,
      radar: undefined,
      line: undefined,
      users: [1404,163,249,538,1351,1083,479,1398,1002,1135,798,609,802,814,780,956,],
      user:{userId:undefined, steamId:'', playtime:0},
      stats: [],
      games: [],
      toggleAll: false,
      dtFrom: new Date(2018, 1, 1),
      dtTo: new Date(2021, 1, 1),
      bar: 'radar',
      barData: {
        labels: ['Lona', 'Mona', 'Dona', 'Nona', 'Ramona'],
        datasets: [{
          label: 'Score between 5 pupils',
          data: [10, 4.8, 17.7, 20, 14],
          backgroundColor: ['red','blue','rgba(171, 122, 232, 0.4)','rgba(27, 25, 169, 0.4)','rgba(141, 47, 108, 0.4)'],
          borderColor: ['rgba(17, 50, 64, 1)','rgba(94, 72, 0, 1)','rgba(171, 122, 232, 1)','rgba(27, 25, 169, 1)','rgba(141, 47, 108, 1)'],
          borderWidth: 1
        }, {
          label: 'Score between 5 pupils',
          data: [1, 20, 17.7, 20, 14],
          backgroundColor: ['red','blue','rgba(171, 122, 232, 0.4)','rgba(27, 25, 169, 0.4)','rgba(141, 47, 108, 0.4)'],
          borderColor: ['rgba(17, 50, 64, 1)','rgba(94, 72, 0, 1)','rgba(171, 122, 232, 1)','rgba(27, 25, 169, 1)','rgba(141, 47, 108, 1)'],
          borderWidth: 1
        }]
      },
    }
  },
  computed:{
    SelectedGames(){
      return this.games.filter((game) => game.selected)
    }
  },
  methods: {
    onUserChange(event) {
      if (event.target.name === 'player') {
        this.games = [];
        this.user = { userId:event.target.value, steamId:'', playtime:0 };
        this.reloadGraph();
      }
    },
    reloadGraph() {
      console.log("reloadGraph called");
      console.log('Selected user: '+JSON.stringify(this.user));
      console.log('Selected games: '+JSON.stringify(this.SelectedGames));
      if (!this.user.userId) {
        this.$refs.playerError.innerHTML = 'Please select a user!';
        this.$refs.playerError.style = "display: block";
        this.$refs.playerInfo.style = "display: none";
        return;
      } else {
        this.$refs.playerError.style = "display: none";
        this.$refs.playerInfo.style = "display: block";
      }
      console.log('flush previous graph');
      if (this.radar) {this.radar.destroy();this.radar=undefined;}
      if (this.line) {this.line.destroy();this.line=undefined;}
      this.loading = true;
      axios.get(true ? "https://skillquest.ai:3000/test" : "http://localhost:3000/test", {
        params: {
          userId: this.user.userId,
          gamesSelected: this.SelectedGames
        }
      }).then(({ data: res }) => {
        console.log(res)
        this.loading = false;
        this.stats = res.levels;
        this.games = res.gamesData;
        this.user = { ...this.user, steamId:res.steamId, playtime:res.totalPlaytime };
        this.renderRadar(this.stats, res.maxLevel);
        this.renderLinear(this.stats);
      }).catch((e) => {
        console.log(e);
        this.$refs.playerInfo.style = "display: block";
        this.$refs.playerError.style = "display: block";
        this.loading = false;
        this.$refs.playerError.innerHTML = e.response.data.error || e;
        this.user = { ...this.user, steamId:e.response.data.graph.steamId || '', playtime:e.response.data.graph.totalPlaytime || 0 };          
      })
    },
    handleDateChange() {console.log(this.games)},
    renderRadar(stats, maxLevel) {
      console.log(document.getElementById('radar-chart'))
      const chrt = document.getElementById('radar-chart');
      const canvasParent = chrt.parentElement;
      const aspectRatio = canvasParent.clientWidth / canvasParent.clientHeight;
      const desiredWidth = 640; // Desired width of the canvas
      // Set canvas dimensions while maintaining aspect ratio
      chrt.width = desiredWidth;
      chrt.height = desiredWidth / aspectRatio;
      var data = { 'labels': [], 'datasets': [] };
      var ds = {};
      var options = {
        maintainAspectRatio: true,
        layout: {padding: {top: 20,left: 0,right: 0,bottom: 20}},
        scale: {ticks: {beginAtZero: true,min: 0,max: maxLevel,stepSize: Math.ceil(maxLevel / 6)}}
      };

      /*
      // PERFORMANCE: this is about ~20% slower than the original (it is probably simpler to do this in the backend)
      const labels = [];
      const datasets = [];
      stats.forEach((stat) => {
        if (options.scale.ticks.min > stat.level) options.scale.ticks.min = stat.level;
        if (options.scale.ticks.max < stat.level) options.scale.ticks.max = stat.level;
        labels.push(stat.skill);
        if (!datasets.map((d) => d.label).includes(stat.category)) {
          const indexes = stats.filter((s) => s.category === stat.category).map((s) => s.order);
          const levels = stats.reduce((a,s,i) => {a.push(indexes.includes(i) ? s.level : 0);return a;}, []);
          datasets.push({label:stat.category, backgroundColor:stat.category_color, borderColor:stat.category_color, data:levels});
        }
      });

      console.log(`Radar chart data:\n- Max: ${options.scale.ticks.max}\n- Min: ${options.scale.ticks.min}\n- Labels: ${labels}\n- Datasets: ${JSON.stringify(datasets)}`);
      */

      var all_levels = [];
      var skills_ids = [];
      var idx = 0;
      // Calculate min and max values for the scale
      var minValue = Number.MAX_VALUE;
      var maxValue = Number.MIN_VALUE;

      stats.forEach((skill) => {
        if (skill.values.length) { // Skip if not value
          // Add label
          if (!(data.labels.includes(skill.skill))) {  data.labels.push(skill.skill);  }
          // Check group  
          if (!(skill.category in ds)) ds[skill.category] = { 'color': skill.category_color, 'idx': [] };
          ds[skill.category].idx.push(idx);
          idx++;
          // Put level
          all_levels.push(skill.level);
          skills_ids.push(skill.skill_id);
          // Update min and max values
          minValue = Math.min(minValue, skill.level);
          maxValue = Math.max(maxValue, skill.level);
        }
      });
      
      // Update scale min and max with calculated values
      options.scale.ticks.min = minValue;
      options.scale.ticks.max = maxValue;
      console.log(ds)
      for (var cat in ds) {
        var ptr_data = all_levels.slice();
        for (var i = 0; i < all_levels.length; i++) {
          if (!(ds[cat].idx.includes(i))) {
            ptr_data[i] = 0;
          }
        }
        var ptr = {
          label: cat,
          backgroundColor: ds[cat].color,
          borderColor: ds[cat].color,
          data: ptr_data
        };
        data.datasets.push(ptr);
      }
      console.log('Chart data: '+JSON.stringify(data))
      this.radar = new Chart(chrt, {
        type: "radar",
        data: data,
        options: options
      });
    },
    renderLinear(stats) {
      const chrt = document.getElementById('line-chart');

      const aggregatedDatasets = stats.map(field => {
        const aggregatedData = [];

        field.values.forEach(value => {
          const date = new Date(value.date);
          const existingData = aggregatedData.find(data => data.x.getTime() === date.getTime());

          if (existingData) {
            existingData.y += value.value;
            existingData.count++;
          } else {
            aggregatedData.push({
              x: date,
              y: value.value,
              count: 1,
            });
          }
        });

        const processedData = aggregatedData.map(data => ({
          x: data.x,
          y: data.y / data.count, // Calculate the average
        }));

        return {
          label: field.skill,
          data: processedData,
          borderColor: field.color,
          backgroundColor: field.color,
          fill: false,
        };
      });

      const config = {
        type: 'line',
        data: { datasets: aggregatedDatasets },
        options: {
          scales: {
            x: {
              type: 'time',
              time: {unit: 'month',displayFormats: {month: 'MMMM'}},
              title: {display: true,text: 'Date'},
            },
            y: {
              ticks: {beginAtZero: true,},
              scaleLabel: {display: true,labelString: 'Average Value',},
            },
          },
        },
      };
      this.line = new Chart(chrt, config);
    },
    clearLinearAll() {
      this.toggleAll = !this.toggleAll;
      var ref = this;
      this.linear.data.datasets.forEach(function (ds) {
        ds.hidden = ref.toggleAll;
      });
      this.linear.update();
    },
  }
}
</script>

<style scoped>
@import "~@fortawesome/fontawesome-free/css/all.css";
.chart {
  display: flex;
  justify-content: center;
  align-items: center;
}

canvas {
  width: 640px !important;
  height: 640px !important;
}
.reload {
  background-color: rgb(0, 67, 122);
  border-radius: 10px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: flex;
  font-size: 16px;
}
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2px;
  width:2px;
  margin-top:10%;
  margin-left:50%;
  font-size: 2rem;
  z-index: -1;
  color: #007bff; /* Blue color, you can adjust this */
  animation: spin 1s infinite linear;
}

.dropdown-item {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.spin {  animation: spin 1s infinite linear;}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>