<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
        <circle id="circle" cx="26" cy="26" r="24" transform="matrix(-1 0 0 1 52 0)" :stroke="color" stroke-width="4"/>
        <path id="compass" d="M26 7.88154L21.2659 20.6751L21.1064 21.1064L20.6751 21.2659L7.88154 26L20.6751 30.7341L21.1064 30.8936L21.2659 31.3249L26 44.1185L30.7341 31.3249L30.8936 30.8936L31.3249 30.7341L44.1185 26L31.3249 21.2659L30.8936 21.1064L30.7341 20.6751L26 7.88154Z" :stroke="color" stroke-width="2"/>
        <circle id="dot" cx="3" cy="3" r="2" transform="matrix(-1 0 0 1 29 23)" :fill="color" :stroke="color" stroke-width="2"/>
    </svg>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    color: {
      type: String,
      default: '#fff',
    },
  },
}
</script>

<style>
:root {
    --size: 10%;
    --duration: 1.5s;
}
svg {
    display: block;
    margin: auto;
    width: var(--size);
    height: var(--size);
}

svg #circle {
    animation: draw var(--duration) infinite linear;
    animation-direction: alternate;
}

svg #compass {
    transform-origin: center center;
    animation: rotate-and-scale var(--duration) infinite linear;
    animation-direction: alternate;
}

svg #dot {
    animation: radius var(--duration) infinite linear;
    animation-direction: alternate;
}

@keyframes draw {
    from {
        stroke-dashoffset: 150;
        stroke-dasharray: 150;
    }
    to {
        stroke-dashoffset: 0;
        stroke-dasharray: 0;
    }
}

@keyframes rotate-and-scale {
    from {  
        rotate: 0deg;
    }
    to {
        rotate: -360deg;
        scale: 0.75;
        stroke-width: 4;
    }
}

@keyframes radius {
    from {
        r: 4;
    }
    to {
        r: 1;
    }
}
</style>