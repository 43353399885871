<template>
  <div class="home-container">
    <app-header rootClassName="header-root-class-name7"></app-header>
    <div class="home-hero section">
      <app-h1
        H1="Train your soft skills and up-level your life"
        rootClassName="h1-root-class-name"
      ></app-h1>
      <div class="home-container1">
        <p class="home-text p">
          We have carefully curated a range of commercial games that are proven to
          enhance key soft skills, such as leadership, teamwork, and
          problem-solving.
        </p>
        <img
          alt="HeroImage1416"
          src="/external/heroimage1416-ldlf-1500w.png"
          class="home-hero-image"
        />
        <router-link to="/register" class="home-navlink">
          <app-button
            text="SIGN UP"
            rootClassName="button-root-class-name1"
            class="home-component2"
          ></app-button>
        </router-link>
      </div>
    </div>
    <div class="home-games section-full">
      <h2 class="home-text01">Some of our games</h2>
	    <Games/><!-- Scroller -->
	  </div>
    <div class="home-go-to section">
      <div class="home-hi-w-text-wrapper">
        <div class="home-container3">
          <img
            alt="pastedImage"
            src="/external/pastedimage-qyca9-600w.png"
            class="home-illustration"
          />
        </div>
        <div class="home-container4">
          <app-h2
            H2="Your go-to destination for games that improve your soft skills"
            rootClassName="h2-root-class-name2"
          ></app-h2>
          <p class="home-text02 p">
            Play, track, and develop important life skills that are critical to
            success in today&apos;s world. Whether you&apos;re an individual
            looking to improve your professional prospects or a company seeking to
            up-skill your workforce, Soft Skill Games has something for everyone.
          </p>
          <router-link to="/how-it-works" class="home-navlink1">
            <app-button class="home-component4"></app-button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="home-mobile-games section-full">
      <h2 class="home-text01">Test your skills now!</h2>
	    <MobileGames/>
	  </div>
    <div class="home-testimonials section-full">
      <app-h2 rootClassName="h2-root-class-name"></app-h2>
      <Testimonials/> <!-- Testimonials Scroller -->
    </div>
    <app-footer/>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import AppH1 from '../components/h1'
import AppButton from '../components/button'
import AppH2 from '../components/h2'
import AppFooter from '../components/footer'
import Testimonials from '../components/testimonials'
import Games from '../components/games'
import MobileGames from '../components/mobile-games.vue'

export default {
  name: 'Home',
  components: {
    AppHeader,
    AppH1,
    AppButton,
    AppH2,
    AppFooter,
    Testimonials,
    Games,
    MobileGames,
  },
  metaInfo: {
    title: 'SkillQuest',
    meta: [
      {
        property: 'og:title',
        content: 'SkillQuest',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  gap: var(--dl-space-space-sixunits);
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  border-color: var(--dl-color-app-primary);
  border-width: 0px;
  flex-direction: column;
}
.home-hero {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  position: relative;
  align-self: center;
  align-items: flex-start;
  padding-left: var(--dl-space-space-sixunits);
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: column;
}
.home-container1 {
  display: flex;
  flex-direction: column;
}
.home-text {
  z-index: 1;
  max-width: 551px;
  font-family: "Fredoka";
  font-weight: 400px;
}
.home-hero-image {
  right: 0;
  width: 50%;
  bottom: -63px;
  position: absolute;
  border-color: transparent;
  margin-right: var(--dl-space-space-fourunits);
}
.home-navlink {
  display: contents;
}
.home-component2 {
  text-decoration: none;
}
.home-games,
.home-mobile-games {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  height: 1266px;
  display: flex;
  overflow: hidden;
  margin-top: 69px;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/waves-bg-1300h.png");
  background-repeat: no-repeat;
  background-position: center;
}
.home-text01 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 40px;
  align-self: auto;
  font-style: normal;
  text-align: center;
  font-family: Fredoka;
  font-weight: 500;
  line-height: 39px;
  font-stretch: normal;
  padding-bottom: 0px;
}
.home-go-to {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-threeunits);
  margin-bottom: 304px;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-hi-w-text-wrapper {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
}
.home-container3 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-illustration {
  flex: 1;
  width: 100%;
  max-width: 590px;
}
.home-container4 {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.home-text02 {
  max-width: 537px;
}
.home-navlink1 {
  display: contents;
}
.home-component4 {
  text-decoration: none;
}
.home-mobile-games {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.home-testimonials {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0;
}
@media(max-width: 991px) {
  .home-hero {
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-container1 {
    gap: var(--dl-space-space-fourunits);
  }
  .home-games,
  .home-mobile-games {
    height: 1234px;
  }
  .home-mobile-games {
    gap: var(--dl-space-space-fourunits);
    flex: 0 0 auto;
    display: flex;
    align-items: anchor-center;
    flex-direction: column;
    padding: 0;
  }
  .home-hi-w-text-wrapper {
    flex-direction: column-reverse;
  }
  .home-container4 {
    position: relative;
    align-items: center;
  }
  .home-text02 {
    text-align: center;
  }
  .home-mobile-games {
    align-items: center;
  }
  .home-testimonials {
    align-items: center;
  }
}
@media(max-width: 767px) {
  .home-container {
    gap: var(--dl-space-space-threeunits);
  }
  .home-hero {
    gap: var(--dl-space-space-twounits);
  }
  .home-container1 {
    gap: var(--dl-space-space-twounits);
    align-items: center;
  }
  .home-text {
    text-align: center;
  }
  .home-hero-image {
    width: 100%;
    position: unset;
  }
  .home-games,
  .home-mobile-games {
    flex-direction: column;
  }
  .home-illustration {
    width: 100%;
  }
  .home-container4 {
    align-self: center;
    align-items: center;
  }
}
@media(max-width: 479px) {
  .home-container {
    align-items: center;
    padding-right: 0px;
  }
  .home-hero {
    padding-left: var(--dl-space-space-twounits);
  }
  .home-container1 {
    gap: var(--dl-space-space-fourunits);
    align-items: center;
  }
  .home-hero-image {
    margin-right: 0px;
  }
  .home-text01 {
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    padding-bottom: 0px;
  }
  .home-container4 {
    align-self: center;
    align-items: center;
  }
}
</style>
