<template>
  <section class="mobile_games_wrapper">
      <div class="mobile_games_container">
        <div v-if="!isGameSet" class="mobile_games">
          <div v-for="item in games" class="mobile_games_item">
            <p class="p mobile_games_skill">{{ item.skill }}</p>
            <button class="mobile_games_button" :class="{ 'disabled': isGameSet || isLoading }" @click="setGame(item.appid)">
              <img :alt=item.name :src=item.src class="mobile_games_image" :class="{ 'disabled': isGameSet || isLoading }"/>
              <p class="p">{{ item.name }}</p>
            </button>
        </div>
		  </div>
      <Loading v-if="isGameSet && isLoading"/>
      <p class="p" v-if="isGameSet && isPortrait">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#fff" d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160 352 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l111.5 0c0 0 0 0 0 0l.4 0c17.7 0 32-14.3 32-32l0-112c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1L16 432c0 17.7 14.3 32 32 32s32-14.3 32-32l0-35.1 17.6 17.5c0 0 0 0 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.8c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352l34.4 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L48.4 288c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"/></svg>
        Please, turn your device sideways
      </p>
      <div class="unity_container" :class="{ 'is_mobile': isMobile }" v-if="isGameSet">
        <div id="unity-container" :class="{ 'is_mobile': isMobile }" v-if="!isPortrait">
          <canvas id="unity-canvas" v-show="isPlaying"></canvas>
        </div>
      </div>
		</div>
	</section>
</template>

<script>
import Loading from './loading.vue';

export default {
  name: 'MobileGames',
  components: {
    Loading
  },
  data() {
    return {
      isMobile: true,
      isPortrait: true,
      isGameSet: false,
      isLoading: false,
      isPlaying: false,
      game: null,
      games: [
        {name:'Truz Syker', skill:'Critical Thinking', appid:'critical-thinking', version:'1.1'}, 
        {name:'Creative Island', skill:'Creativity', appid:'creativity', version:'1.1'}, 
        {name:'Super Visor', skill:'Cognitive Flexibility', appid:'cognitive-flexibility', version:'1.1'}
      ].filter((g) => g.name && g.skill && g.appid && g.version).map((g) => ({...g, src:`/external/${g.appid}.png`})),
    }
  },
  mounted() {
    window.Exit = this.closeGame;
    this.isMobile = (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
    this.checkOrientation();
    window.addEventListener('resize', this.checkOrientation);
  },
  methods: {
    checkOrientation() {
      const portrait = window.innerHeight > window.innerWidth;
      if (this.isPortrait === portrait) return;
      this.isPortrait = portrait;
      if (this.isGameSet) {
        if (!this.isPortrait) this.loadGame();
        else if (this.isMobile) this.closeGame();
      }
    },
    setGame(appid) {
      if (this.isGameSet || !this.games.some((g) => g.appid === appid)) return;
      this.game = this.games.find((g) => g.appid === appid);
      this.isGameSet = true;
      if (this.isPortrait) return;
      this.loadGame();
    },
    closeGame() {
      this.game = null;
      this.isGameSet = false;
      this.isLoading = false;
      this.isPlaying = false;
    },
    startGame() {
      this.isLoading = false;
    },
    loadGame() {
      this.isLoading = true;
      const url = `/games/${this.game.appid}/${this.game.version}.`;
      const script = document.createElement('script');
      script.src = url+'loader.js';
      script.onload = () => {
        const canvas = document.getElementById('unity-canvas');
        const fullscreenButton = document.getElementById('unity-fullscreen-button');
        const contextOptions = {
          dataUrl: url+'data.unityweb',
          frameworkUrl: url+'framework.js.unityweb',
          codeUrl: url+'wasm.unityweb',
        };
        if (canvas && canvas.getContext) {
          let context = canvas.getContext('webgl2') || canvas.getContext('webgl');
          if (!context) console.error('WebGL is not supported');
          else createUnityInstance(canvas, contextOptions).then((unityInstance) => {
            this.startGame();
            this.isPlaying = true;
            if (!this.isMobile) {
              unityInstance.SetFullscreen(1);
              document.addEventListener('fullscreenchange', (event) => {if (!document.fullscreenElement) this.closeGame();});
            }
          });
        }
      };
      document.body.appendChild(script);
    }
  }
}
</script>
  
<style scoped>
.p {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  align-self: auto;
  font-style: normal;
  text-align: center;
  font-family: Fredoka;
  font-weight: 50;
  line-height: 50px;
  font-stretch: normal;
  padding-bottom: 0px;
  word-break: break-word;
}
.mobile_games_wrapper {
  /* How much items are wide */
  --mobile-games-size: var(--dl-size-size-xlarge);
  width: 100%;
}
.mobile_games {
  display: flex;
  justify-content: space-evenly;
}
.mobile_games_item {
  transition: transform .2s;
  /* If you want to have it continuous without any spaces, remove two lines below */
  box-sizing: border-box;
  margin-inline: 1vw;
}
.mobile_games_button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
}
.mobile_games_image {
  width: var(--mobile-games-size);
  height: var(--mobile-games-size);
  display: block;
  border-radius: 27px;
  object-fit: cover;
  object-position: center;
}
.disabled {
  filter: grayscale(100%);
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
.mobile_games_skill {
  font-size: 18px;
  font-weight: 100;
}
.mobile_games_item:hover,
.mobile_games_item:focus { 
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1); 
}
.fixed-center {
  position: fixed; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
}
.unity_container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 ratio */
  z-index: 1; 
}
.is_mobile { 
  position: fixed !important;
  width: 100% !important; 
  height: 100% !important; 
}
#unity-container { 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%; 
}
#unity-canvas { 
  display: block;
  width: 100%;
  height: 100%;
}
#unity-fullscreen-button { 
  cursor: pointer; 
  float: center; 
  height: auto;
  color: rgba(255, 255, 255, 1);
}
@media(max-width: 860px) { 
  .mobile_games_wrapper {
    --mobile-games-size: var(--dl-size-size-large);
  }
}
@media(max-width: 440px) { 
  .mobile_games_wrapper {
    --mobile-games-size: var(--dl-size-size-medium);
  }
}
@media(max-width: 260px) { 
  .mobile_games_wrapper {
    --mobile-games-size: var(--dl-size-size-small);
  }
}
/*
--dl-size-size-xxlarge: 288px;
--dl-size-size-xlarge: 192px;
--dl-size-size-large: 144px;
--dl-size-size-medium: 96px;
--dl-size-size-small: 48px;
--dl-size-size-xsmall: 16px;
*/
</style>